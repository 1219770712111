section .section-title {
  text-align: center;
  /* color: #06c167;
  margin-bottom: 0px;
  text-transform: uppercase; */
}
#what-we-do {
  background: #ffffff;
}
#what-we-do .card {
  padding: 1rem !important;
  border: none;
  margin-bottom: 1rem;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#what-we-do .card:hover {
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#what-we-do .card .card-block {
  padding-left: 50px;
  position: relative;
}
#what-we-do .card .card-block a {
  color: #06c167 !important;
  font-weight: 700;
  text-decoration: none;
}
#what-we-do .card .card-block a i {
  display: none;
}
#what-we-do .card:hover .card-block a i {
  display: inline-block;
  font-weight: 700;
}
#what-we-do .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #06c167;
  left: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
#what-we-do .card .block-1:before {
  content: "\f041";
}
#what-we-do .card .block-2:before {
  content: "\f0cb";
}
#what-we-do .card .block-3:before {
  content: "\f1ec";
}
#what-we-do .card .block-4:before {
  content: "\f0a1";
}
#what-we-do .card .block-5:before {
  content: "\f27a";
}
#what-we-do .card .block-6:before {
  content: "\f3ed";
}
#what-we-do .card:hover .card-block:before {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
