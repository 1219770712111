#container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 800px;
  height: 800px;
  background-color: #fff;
}
.question {
  width: 500px;
  height: 500px;
}

.question-container {
  width: 100%;
  height: 100%;
}
