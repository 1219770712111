/*-----------------------------------------------------------------------------------

 Graph chart 

-----------------------------------------------------------------------------------*/

.con1 span {
  font-weight: 400;
  color: #b6b6b6;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: block;
}

.con1 h2 {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 37px;
  line-height: 38px;
  margin: 0;
  /* background: url(../images/line.png) no-repeat left bottom; */
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.con1 {
  padding-top: 0px;
}

.con1 .p {
  font-weight: 400;
  color: #8a8a8al;
  font-size: 13px;
  line-height: 26px;
}

.tgimg {
  float: left;
  min-width: 55px;
  padding-top: 8px;
  width: 10%;
}
.tgcon {
  float: left;
  width: 85%;
}
.tgcon span {
  display: block;
  font-weight: 400;
  color: #1a1a1a;
  font-size: 18px;
}
.tgcon p {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 13px;
  line-height: 26px;
}
.tags {
  padding-top: 60px;
}
.tg {
  padding-bottom: 45px;
}
.img3 img {
  width: 45%;
  height: 64vh;
  border-radius: 6px;
  border: #e2e7ec solid 1px;
  -webkit-box-shadow: 7px 6px 34px -9px rgba(144, 155, 165, 0.48);
  -moz-box-shadow: 7px 6px 34px -9px rgba(144, 155, 165, 0.48);
  box-shadow: 7px 6px 34px -9px rgba(144, 155, 165, 0.48);
}
.section3 {
  padding-bottom: 50px;
  margin-top: 30px;
}
/* End Grap chart */
