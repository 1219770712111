@import url(https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap);

#container {
  box-sizing: border-box;
  padding: 10px;
  width: 800px;
  height: 800px;
  background-color: #fff;
}
.question {
  width: 500px;
  height: 500px;
}

.question-container {
  width: 100%;
  height: 100%;
}

/* layout.css Style */
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  top: 0px;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " faites-le glisser ici. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

@media print {
  .noprint {
    display: none !important;
  }
}

.flexrow {
  display: flex;
}
.flexrow > * {
  flex: 1 1;
  margin: 1em;
}

:root {
  --primary: #fff;
}

.btnc {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

.green {
  background: #25ce4a;
  color: #fff;
  border: none;
}

.green:hover {
  background-color: #242424;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}
/* 
.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
} */

/* .nav-item:hover {
  border-bottom: 2px solid #f00946;
} */

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  /* .fa-times {
    color: #fff;
    font-size: 2rem;
  } */

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.hero {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.pricing__section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
}

.pricing__container-card {
  background: linear-gradient(45deg, #0660e9 0%, #276afb 100%);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 500px;
  text-decoration: none;
  border-radius: 4px;
}

.pricing__container-card:nth-child(2) {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background: #242424;
}

.pricing__container-card:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #1c2237;
}

.pricing__container-card:nth-child(2) {
  margin: 0 24px;
}

.pricing__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
}

.pricing__heading {
  color: #1c2237;
  margin-bottom: 24px;
}

.pricing__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fff;
}

.pricing__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
}

.pricing__container-cardInfo h4 {
  font-size: 40px;
}

.pricing__container-cardInfo p {
  font-size: 14px;
  margin-bottom: 24px;
}

.pricing__container-features {
  margin: 16px 0 32px;
  list-style: none;
}

.pricing__container-features li {
  margin-bottom: 10px;
}

.pricing__container-features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 24px 0;
}

@media screen and (max-width: 960px) {
  .pricing__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pricing__container-card {
    width: 90%;
  }

  .pricing__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing__container-card:nth-child(2) {
    -webkit-transform: scale(1);
            transform: scale(1);
    background: #242424;
    margin: 24px;
  }

  .pricing__container-card:hover {
    -webkit-transform: none;
            transform: none;
  }
}

section .section-title {
  text-align: center;
  /* color: #06c167;
  margin-bottom: 0px;
  text-transform: uppercase; */
}
#what-we-do {
  background: #ffffff;
}
#what-we-do .card {
  padding: 1rem !important;
  border: none;
  margin-bottom: 1rem;
  transition: 0.5s all ease;
}
#what-we-do .card:hover {
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#what-we-do .card .card-block {
  padding-left: 50px;
  position: relative;
}
#what-we-do .card .card-block a {
  color: #06c167 !important;
  font-weight: 700;
  text-decoration: none;
}
#what-we-do .card .card-block a i {
  display: none;
}
#what-we-do .card:hover .card-block a i {
  display: inline-block;
  font-weight: 700;
}
#what-we-do .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #06c167;
  left: 0;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
#what-we-do .card .block-1:before {
  content: "\f041";
}
#what-we-do .card .block-2:before {
  content: "\f0cb";
}
#what-we-do .card .block-3:before {
  content: "\f1ec";
}
#what-we-do .card .block-4:before {
  content: "\f0a1";
}
#what-we-do .card .block-5:before {
  content: "\f27a";
}
#what-we-do .card .block-6:before {
  content: "\f3ed";
}
#what-we-do .card:hover .card-block:before {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  transition: 0.5s all ease;
}

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 0 60px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.17);
}

.our-services .icon {
  margin-bottom: -21px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
}

.our-services:hover h4,
.our-services:hover p {
  color: #fff;
}

.speedup:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
}

.settings:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
}

.privacy:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
}

.backups:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
}

.ssl:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%);
}

.database:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
}

/*-----------------------------------------------------------------------------------

 Graph chart 

-----------------------------------------------------------------------------------*/

.con1 span {
  font-weight: 400;
  color: #b6b6b6;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: block;
}

.con1 h2 {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 37px;
  line-height: 38px;
  margin: 0;
  /* background: url(../images/line.png) no-repeat left bottom; */
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.con1 {
  padding-top: 0px;
}

.con1 .p {
  font-weight: 400;
  color: #8a8a8al;
  font-size: 13px;
  line-height: 26px;
}

.tgimg {
  float: left;
  min-width: 55px;
  padding-top: 8px;
  width: 10%;
}
.tgcon {
  float: left;
  width: 85%;
}
.tgcon span {
  display: block;
  font-weight: 400;
  color: #1a1a1a;
  font-size: 18px;
}
.tgcon p {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 13px;
  line-height: 26px;
}
.tags {
  padding-top: 60px;
}
.tg {
  padding-bottom: 45px;
}
.img3 img {
  width: 45%;
  height: 64vh;
  border-radius: 6px;
  border: #e2e7ec solid 1px;
  box-shadow: 7px 6px 34px -9px rgba(144, 155, 165, 0.48);
}
.section3 {
  padding-bottom: 50px;
  margin-top: 30px;
}
/* End Grap chart */

.footer-container {
  background-color: #1c2237;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 24px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media print {
  .dataTables_filter {
    display: none !important;
  }

  .dataTables_length {
    display: none !important;
  }

  .page-header {
    display: none !important;
  }
  .info {
    display: none !important;
  }
  .btn {
    display: none !important;
  }
}

input,
.StripeElement {
  display: block;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::-webkit-input-placeholder {
  color: #aab7c4;
}

input:-ms-input-placeholder {
  color: #aab7c4;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.rdw-editor-main {
  background-color: #f5f6f9;
  border: 1px solid #eee;
  margin-bottom: 17px;
  padding: 7px 17px 7px 17px;
  line-height: 27px;
}
.public-DraftEditor-content {
  min-height: 100px;
}

/* questionnaire printing */

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* layout.css Style */
.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 200px;
  text-align: center;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  top: 0px;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " faites-le glisser ici. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

